<style lang="scss" scoped>
.large-title {
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  height: 52px;
  .title {
    font-weight: bold;
    font-size: 16px;
    color: rgba(89, 87, 87, 1);
  }

  .line {
    flex-grow: 1;
    margin-left: 16px;
    height: 1px;
    background: rgba(89, 87, 87, 0.1);
  }
}
</style>

<template>
  <div class="header">
    <div class="left-icon">
      <img src="@/assets/header/logo.svg" alt="" />
    </div>
    <div class="drop-menu" @click="openSelect">
      <div class="select-name">{{ $store.state.selectName }}</div>
      <div class="drop-icon" :class="{ 'rotate-icon': isOpenDialog }">
        <img src="@/assets/icon/drop.svg" alt="" />
      </div>
    </div>
    <div class="drop-line" v-show="isOpenDialog"></div>
    <div
      class="dialog-drop"
      v-show="isOpenDialog"
      @click.stop.prevent="isOpenDialog = false"
    >
      <div class="select-content" @click.stop.prevent>
        <template v-for="cl in selectList">
          <div class="large-title">
            <div class="title">{{ cl.name }}</div>
            <div class="line"></div>
          </div>
          <div class="cl" v-for="item of cl.children">
            <div class="big-title">
              <div class="title-icon">
                <img :src="item.img" alt="" />
              </div>
              <div class="title-name">
                <p>{{ item.showTitle }}</p>
              </div>
            </div>
            <div class="big-content">
              <div
                class="content-item"
                :class="{ 'select-item': j.m == $store.state.selectMesh }"
                v-for="j in item.child"
                @click="selectMesh(j)"
              >
                <div class="left-tubiao" v-if="j.count">
                  <img src="@/assets/icon/drop-left-icon.svg" alt="" />
                  <span>*{{ j.count }}</span>
                </div>
                <div class="right-text">
                  <span v-html="j.name"></span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/lib/bus.js";
export default {
  name: "cus-header",
  data() {
    return {
      isOpenDialog: false,
      selectList: [
        {
          name: "BATTERY",
          children: [
            {
              show: false,

              showTitle: "LFP9-30kWh/HV",
              tName: "LFP9-30kWh/HV",
              img: require("@/assets/icon/drop-title1.svg"),
              child: [
                {
                  count: 3,
                  name: "LFP 9kWh/HV",
                  m: "LFP_9kWhHV",
                  enNow: "LFP9-30kWh/HV",
                },
                {
                  count: 4,
                  name: "LFP 12kWh/HV",
                  m: "LFP_12kWhHV",
                  enNow: "LFP9-30kWh/HV",
                },
                {
                  count: 5,
                  name: "LFP 15kWh/HV",
                  m: "LFP_15kWhHV",
                  enNow: "LFP9-30kWh/HV",
                },
                {
                  count: 6,
                  name: "LFP 18kWh/HV",
                  m: "LFP_18kWhHV",
                  enNow: "LFP9-30kWh/HV",
                },
                {
                  count: 7,
                  name: "LFP 21kWh/HV",
                  m: "LFP_21kWhHV",
                  enNow: "LFP9-30kWh/HV",
                },
                {
                  count: 8,
                  name: "LFP 24kWh/HV",
                  m: "LFP_24kWhHV",
                  enNow: "LFP9-30kWh/HV",
                },
                {
                  count: 9,
                  name: "LFP 27kWh/HV",
                  m: "LFP_27kWhHV",
                  enNow: "LFP9-30kWh/HV",
                },
                {
                  count: 10,
                  name: "LFP 30kWh/HV",
                  m: "LFP_30kWhHV",
                  enNow: "LFP9-30kWh/HV",
                },
              ],
            },

            {
              show: false,

              showTitle: "LFP5-10kWh/LV",
              tName: "LFP 5-10KWH/LV",
              img: require("@/assets/icon/drop-title2.svg"),
              child: [
                {
                  count: 0,
                  name: "LFP 5kWh/LV",
                  // m: "LFP_5kWhHV",
                  m: "LFP_5kWhLV",
                  enNow: "LFP 5-10KWH/LV",
                },
                {
                  count: "",
                  name: "LFP 10kWh/LV",
                  // m: "LFP_10kWhHV",
                  m: "LFP_10kWhLV",
                  enNow: "LFP 5-10KWH/LV",
                },
              ],
            },
            {
              show: false,

              showTitle: "LFP5000/LV",
              tName: "LFP 5000",
              img: require("@/assets/icon/drop-title3.svg"),
              child: [
                {
                  count: "",
                  name: "LFP 5000",
                  m: "LFP_5000",
                  enNow: "LFP 5000",
                },
              ],
            },
          ],
        },
        {
          name: "Inverter",
          children: [
            {
              show: false,
              showTitle: "Single Phase",
              tName: "Single Phase",
              img: require("@/assets/icon/drop-title4.svg"),
              child: [
                {
                  count: "",
                  name: "SKT3-10KTL (Single Phase)",
                  m: "Single-Phase",
                  enNow: "Single Phase",
                },
              ],
            },
            {
              show: false,
              showTitle: "Three Phase",
              tName: "Three Phase",
              img: require("@/assets/icon/逆变器单相.svg"),
              child: [
                {
                  count: "",
                  name: "SKT4-12KTL3H (Three Phase)",
                  m: "Three-Phase",
                  enNow: "Three Phase",
                },
              ],
            },
          ],
        },
        {
          name: "PV MODULE",
          children: [
            {
              tName: "TOPCON",
              showTitle: "TOPCon PV MODULE",
              img: require("@/assets/icon/drop-title5.svg"),
              child: [
                {
                  count: "",
                  name: "430W ALL BLACK 108CELLS",
                  m: "TOPCon-108cells",
                  enNow: "TOPCON",
                },
                {
                  count: "",
                  name: "580W DUAL-GLASS 144CELLS",
                  m: "TOPCon-144cells",
                  enNow: "TOPCON",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  created() {
    bus.$on("changeMesh", this.findItem);
  },
  methods: {
    openSelect() {
      this.isOpenDialog = !this.isOpenDialog;
    },
    findItem(item) {
      let res = item.split("-");
      let nn;
      this.selectList.find((item) => {
        if (item.tName.replaceAll(" ", "") == res[0].replaceAll(" ", "")) {
          item.child.find((j) => {
            if (j.name.replaceAll(" ", "") == res[1].replaceAll(" ", "")) {
              nn = j;
              return;
            }
          });
        }
      });
      this.selectMesh(nn);
    },
    selectMesh(item) {
      this.$store.state.selectMesh = item.m;
      this.$store.state.selectName = `${item.enNow}·${item.name}`;
      this.$store.state.meshType = item.enNow;
      this.isOpenDialog = false;
      if (this.$store.state.isStructure || this.$store.state.isDown) {
        bus.$emit("switchMesh", this.$store.state.selectMesh, "相机2");
      } else {
        bus.$emit("switchMesh", this.$store.state.selectMesh);
      }
      bus.$emit("changeMeshBtn");
      bus.$emit("hiddenSize");

      bus.$emit("three-icon-IsNormal", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  background: #ffffff;
  z-index: 2000;
  width: 100%;
  height: 44px;
  display: flex;
  .left-icon {
    margin-left: 16px;
    height: 100%;
    width: 83px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 18px;
    }
  }
  .drop-menu {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 16px;
    .select-name {
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      letter-spacing: 0.5px;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      white-space: nowrap;
      color: rgba(0, 0, 0, 0.8);
    }
    .drop-icon {
      width: 24px;
      height: 24px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .rotate-icon {
      transform: rotate(180deg);
    }
  }
  .drop-line {
    position: absolute;
    top: 44px;
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
  }
  .dialog-drop {
    position: absolute;
    top: 45px;
    width: 100%;
    padding-bottom: 24px;
    overflow: auto;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10000;
    .select-content {
      width: 100%;
      background: #ffffff;
      padding-bottom: 120px;
      .big-title {
        width: 100%;
        height: 52px;
        display: flex;
        align-items: center;
        &:first-child {
          margin-top: 0px;
        }
        .title-icon {
          width: 40px;
          height: 40px;
          margin-left: 21px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title-name {
          height: 40px;
          display: flex;
          align-items: center;
          margin-left: 4px;
          font-weight: 700;
          font-size: 16px;
          color: #595757;
        }
      }
      .big-content {
        width: 100%;
        padding: 0 16px;
        display: grid;
        grid-template-columns: 168px 168px;
        column-gap: 8px;
        row-gap: 8px;
        .content-item {
          border-radius: 4px;
          height: 44px;
          background: #f8f8f8;
          display: flex;
          align-items: center;
          justify-content: center;
          .left-tubiao {
            height: 19px;
            min-width: 45px;
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 9px;
            }
            span {
              font-weight: 400;
              font-size: 13px;
              line-height: 19px;
              color: rgba(0, 0, 0, 0.6);
              margin-left: 4px;
            }
          }
          .right-text {
            min-width: 92px;
            height: 19px;
            text-align: center;
            display: flex;
            align-items: center;
            span {
              font-weight: 400;
              font-size: 13px;
              line-height: 19px;
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }
        .select-item {
          background: #e08c12;
        }
      }
    }
  }
}
</style>
